<template>
  <div class="app-container"
       v-loading="isLoading"
       element-loading-background="rgba(255, 255, 255, 1)"
  >
    <el-card v-if="!isLoading">
      <h2 class="heading page-heading">{{ $t('page_admin_units.title') }}</h2>
      <can-i :permissions="[$options.ROLE_UNIT_ADMINISTRATION]">
        <v-button
            type="primary"
            icon="post_add"
            @click="$router.push({name: 'create_unit'})"
        >
          {{ $t('page_admin_units.btn_create_unit') }}
        </v-button>
      </can-i>
      <el-table
          @sort-change="customSort"
          :data="unitsToShow"
          style="width: 100%"
      >
        <el-table-column
            :fixed="fixColumns"
            :width="220"
            prop="unitTitle"
            sortable="custom"
            :label="$t('page_admin_units.table_headers.title')"
        />
        <el-table-column
            :width="120"
            prop="typeTrans"
            sortable="custom"
            :label="$t('page_admin_units.table_headers.type')"
        />
        <el-table-column
            :width="170"
            prop="children"
            sortable="custom"
            :label="$t('page_admin_units.table_headers.children')"
        >
          <template #default="{row}">
           <div class="text-center">
             {{ row.children ? row.children : '-' }}
           </div>
          </template>
        </el-table-column>
        <el-table-column
            :width="170"
            prop="monthlyCost"
            sortable="custom"
            :label="$t('page_admin_units.table_headers.monthlyCost')"
        >
          <template #default="{row}">
            <div class="text-right">
              {{ row.type === $options.UNIT_TYPE_EMERGENCY
                ? '-' : $options.moneyFormat(row.monthlyCost) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            width="250"
            prop="purposeTitle"
            sortable="custom"
            :label="$t('page_admin_units.table_headers.purposeTitle')"
        />
        <el-table-column
            :width="120"
            prop="moneyPurpose"
            sortable="custom"
            :label="$t('page_admin_units.table_headers.moneyPurpose')"
        >
          <template #default="{row}">
            <div class="text-right">
              {{ $options.moneyFormat(row.moneyPurpose) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            :width="110"
            sortable
            prop="startedAt"
            :label="$t('page_admin_units.table_headers.purposeDateStart')"
        >
          <template #default="{row}">
            {{ sliceDate(row.startedAt) }}
          </template>
        </el-table-column>
        <el-table-column
            :width="170"
            sortable
            prop="publishedAt"
            :label="$t('page_admin_units.table_headers.publishDate')"
        >
          <template #default="{row}">
            {{ row.publishedAt }}
          </template>
        </el-table-column>
        <el-table-column :fixed="fixColumns ? 'right' : false"
                         :width="180"
                         :label="$t('page_admin_units.table_headers.actions')"
        >
          <template slot-scope="{row}">
            <div class="d-flex justify-between">
              <v-button @click="goToUnitPage(row.unitId)" size="mini">
                {{ $t('page_admin_units.btn_unit_preview') }}
              </v-button>
              <el-popconfirm
                  v-if="row.isPublish"
                  @confirm="deactivateUnit(row.unitId)"
                  :confirm-button-text="$t('page_admin_units.confirm_modal.btn_confirm')"
                  :cancel-button-text="$t('page_admin_units.confirm_modal.btn_cancel')"
                  icon="el-icon-info"
                  :title="$t('page_admin_units.confirm_modal.confirm_text')"
                  icon-color="red"
              >
                <template #reference>
                  <v-button size="mini" type="danger">
                    {{ $t('page_admin_units.btn_deactivate_unit') }}
                  </v-button>
                </template>
              </el-popconfirm>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <v-pagination :items="unitList"
                    @update:items-to-show="unitsToShow = $event"
      />
    </el-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  fetchAdminUnitList,
  deactivateUnit
} from '../shared/services/unit-service'
import {UNIT_TYPE_EMERGENCY} from '../shared/constants/units-constants'
import {UNIT_ABOUT_ROUTE_NAME} from '../../../shared/constants/app-routes-names'
import {ROLE_UNIT_ADMINISTRATION} from '../../../shared/constants/app-permissions'

import VButton from '../../../components/ui/v-button/VButton'

import {moneyFormat} from '../../../shared/utils/moneyFormat'
import {tableSort} from '../../../shared/utils/tableSort'

import CanI from '../../../auth/can-i/CanI'
import VPagination from '../../../components/ui/v-pagination/VPagination'

const DEACTIVATE_UNIT_COMMAND = 'deactivateUnit'

export default {
  name: 'UnitList',
  components: {VPagination, CanI, VButton},
  UNIT_TYPE_EMERGENCY,
  ROLE_UNIT_ADMINISTRATION,
  DEACTIVATE_UNIT_COMMAND,
  moneyFormat,
  data() {
    return {
      unitList: [],
      unitsToShow: [],
    }
  },
  created() {
    this.fetchUnitList()
  },
  inject: ['responsive'],
  methods: {
    deactivateUnit(unitId) {
      deactivateUnit(unitId)
        .then(() => {
          this.$message({
            type: 'success',
            message: `${this.$t('page_admin_units.alerts.deactivate_unit_success')}`
          })
        })
        .catch((e) => {
          this.$message({
            type: 'error',
            message: `${this.$t('alerts.common_error', {status: e.status})}`
          })
        })
    },
    fetchUnitList() {
      fetchAdminUnitList().then((res) => {
        this.unitList = res
      })
      .catch((e) => {
        this.$message({
          type: 'error',
          message: `${this.$t(e)}`
        })
      })
    },
    goToUnitPage(unitId) {
      this.$router.push({name: UNIT_ABOUT_ROUTE_NAME, params: {unitID: unitId}})
    },
    sliceDate(date) {
      return date ? date.slice(0, 10) : '-'
    },
    customSort(sortInfo) {
      this.unitList = tableSort(this.unitList, sortInfo)
    },
  },
  computed: {
    ...mapState('loadingState', {
      isLoading: state => state.isLoading
    }),
    fixColumns() {
      return !this.responsive.isSm
    }
  }
}
</script>

