<template>
  <el-pagination hide-on-single-page
                 layout="prev, pager, next"
                 :page-size="pageSize"
                 :current-page.sync="currentPage"
                 :total="total"
  >
  </el-pagination>
</template>

<script>
export default {
  name: 'VPagination',
  props: {
    pageSize: {
      type: Number,
      required: false,
      default: 15,
    },
    page: {
      type: Number,
      default: 1
    },
    items: {
      type: Array,
      required: true,
    },
    totalServerItems: {
      type: Number,
      required: false,
      default: undefined
    },
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  created() {
    this.currentPage = this.page
  },
  watch: {
    currentPage(val) {
      this.$emit('page-updated', val)
    },
    itemsToShow(val) {
      this.updateItemsToShow(val)
    }
  },
  methods: {
    updateItemsToShow(val) {
      if (!this.isServerSidePagination) {
        this.$emit('update:items-to-show', val)
      }
    }
  },
  computed: {
    isServerSidePagination() {
      return this.totalServerItems !== undefined
    },
    total() {
      return this.isServerSidePagination ? this.totalServerItems : this.items.length
    },
    totalPages() {
      return Math.ceil(this.total / this.pageSize)
    },
    firstIndexToShow() {
      return this.currentPage * this.pageSize - this.pageSize
    },
    lastIndexToShow() {
      return this.firstIndexToShow + this.pageSize
    },
    itemsToShow() {
      if (this.isServerSidePagination) return

      return this.items.slice(this.firstIndexToShow, this.lastIndexToShow)
    }
  }
}
</script>
